<template>
  <div>

    <!-- 레이블 -->
    <div v-if="label">{{ label }}</div>


    <!-- 추가된 첨부파일 -->
    <div
        v-for="(file, idx) in files"
        :key="`file-${idx}`"
        class="file-list">
      <!-- 썸네일 -->
      <div>
        <!-- 이미지 -->
        <a
            target="_blank"
            :href="file.file">
          <div
              v-if="isImage(file.extension)"
              class="thumb"
              :style="thumbStyle(file.file)"></div>
          <!-- 이미지 아닐 땐 파일 확장자명 표시 -->
          <div v-else
               :style="extColor(file.extension)"
               class="thumb ext">{{ upper(file.extension) }}</div>
        </a>
      </div>
      <div class="lp-file-name">{{ file.name }}</div>
      <div class="trash" @click="onDelete(idx)">
        <i class="material-icons">delete</i>
      </div>
    </div>
    <!-- 첨부파일 추가하기 -->
    <div v-if="maxLength > files.length">
      <input class="file-input"
             v-if="uploadReady"
             :accept="acceptExt"
             @click.stop
             :id="uid"
             @change="onFileChange"
             type="file">

      <label :for="uid"
             :class="{'margin-top-8': files.length>0}"
             @click.stop
             class="file-attachment-wrapper">
        <div class="body3 sub4" style="padding:10px 16px">{{ placeholder }}</div>
        <div class="file-attachment">
          <i class="material-icons sub2">link</i>
        </div>
      </label>
    </div>
    <div class="file-desc">{{ description }}</div>

  </div>
</template>

<script>
  import moment from 'moment'
  export default {
    name: 'InputFile',

    mixins: [
    ],

    props: {
      label: {
        type: String,
        required: false
      },
      value: {
        required: false
      },
      acceptExt: {
        type: String,
        default: 'image/gif, image/jpeg, image/png, .docx, .pptx, .xlsx, .hwp, .pdf'
      },
      maxLength: {
        type: Number,
        required: false,
        default: 5
      },
      description: {
        type: String,
        required: false,
        default: ''
      //  첨부파일은 최대 5개, 10MB 까지 등록 가능합니다.\n' +
      //   '파일형식은 이미지(jpg, gif, png), 오피스 문서(hwp, pdf, pptx, docx, xlsx)만 가능합니다.
      },
      placeholder: {
        type: String,
        required: false,
        default: '+ 첨부파일 추가'
      }
    },

    created() {
      this.uid = moment().format('SSSS');
      if(this.value) {
        this.files = this.value;
      }

    },

    data() {
      return {
        uid: '',
        file: undefined,
        uploadReady: true,        // 초기화 용
        files: [],
        items: []
      };
    },

    watch: {
      files(n) {
        if(n) this.$emit('update:value', this.files);
      },
      value(n) {
        this.files = n;

        let file_data = [];
        n.forEach(item=> {
          file_data.push(item.file);
        })
        this.items = file_data;
      },
      items(n) {
        if(n) this.$emit('setValue', this.items)
      }
    },

    computed: {
    },

    methods: {
      onDelete(idx) {
        this.files.splice(idx, 1);
        this.items.splice(idx, 1);
        // removeIndex(this.files, idx);
        // removeIndex(this.items, idx);
      },

      upper(ext) {
        return ext.toUpperCase();
      },

      extColor(ext) {
        let deco = { backgroundColor : '' };
        switch(ext.toLowerCase()) {
          case 'hwp':
            deco.backgroundColor = '#2ebcee';
            break;
          case 'pdf':
            deco.backgroundColor = '#ef4037';
            break;
          case 'docx':
            deco.backgroundColor = '#295498';
            break;
          case 'pptx':
            deco.backgroundColor = '#d14425';
            break;
          case 'xlsx':
            deco.backgroundColor = '#207245';
            break;
        }

        return deco;
      },

      isImage(ext) {
        let isImg = false;
        let imgExt = ['jpg', 'jpeg', 'png', 'bmp', 'gif', 'tiff', 'tif'];
        ext = ext.toLowerCase();
        if(imgExt.indexOf(ext)>-1)
          isImg = true;

        return isImg;
      },
      thumbStyle(img) {
        let deco = {
          backgroundImage: `url("${img}")`,
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        };

        return deco;
      },
      onFileChange(e) {
        this.file = e.target.files[0];
        if(this.file === undefined) return;

        this.uploadReady = false;
        this.$nextTick(() => { this.uploadReady = true; });

        //10MB 또는 파일 maxLength개 까지만 업로드 가능
        let totalSize = 0;
        for(let i=0; i< this.files.length; i++)
          totalSize += this.files[i].size;

        if((totalSize + this.file.size ) > 1024*1024*10 || this.files.length + 1 > this.maxLength) {
          this.toast(`최대 10MB, ${this.maxLength}개 파일까지만 업로드 가능합니다.`);
          return;
        }

        if(this.findItem(this.files, 'name', this.file.name) !== undefined) {
          this.toast('이미 업로드 된 파일입니다.');
          return;
        }

        let reader = new FileReader();
        reader.readAsDataURL(this.file);
        reader.onload = (f) => {
          let file = this.file.name.split('.');
          let ext = file[file.length-1];
          let file_data = f.srcElement.result;
          // console.log('file_data', file_data)
          this.files.push({
            name : this.file.name,
            file : file_data,
            size: this.file.size,
            extension: ext
          });
          this.items.push(file_data);
          this.$emit('setValue', this.items)
        };
      }
    }
  }
</script>

<style lang="stylus" type="stylus" scoped>
  @import '~assets/css/lp_main'
  .file-list
  .file-attachment-wrapper
    border 1px solid $gray1
    background-color #ffffff
    display flex
    justify-content space-between
    width 100%
    height 46px
    border-radius 8px

  .file-list
    margin-top 8px

  .file-list:first-child
    margin-top 0

  .thumb
  .file-attachment
    width 44px
    height 44px

  .ext
    text-align center
    color white
    background-color #064160
    line-height 44px
    font-size 0.7rem
    font-weight 100

  /*.file-attachment-text
    padding-left 12px
    line-height 46px
    font-size 0.9rem
    color $sub4*/

  .file-attachment
    text-align center

  .file-attachment i
    line-height 46px
    font-size 1.6rem

  .file-desc
    font-size 0.7rem
    color rgba(162, 174, 186, 1)
    white-space pre-line
    padding 8px 0

  .lp-file-name
    padding 0 20px
    color #303841
    font-size 0.8rem
    text-overflow ellipsis
    overflow hidden
    white-space pre
    line-height 44px
    text-align left
    flex 1

  .file-input
    width 0
    height 0
    position fixed
    top -100px
    left -100px

  .trash
    width 44px
    height 44px
    text-align center

  .trash i
    line-height 44px
    color rgba(162, 174, 186, 1)
    font-size 1.4rem
</style>
